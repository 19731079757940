<template>
  <ui-component-modal
    modalTitle=""
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="save"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div v-if="pckg">
        <div>
          <input v-model="pckg.Name" class="input" />
        </div>

        <div class="box mt-5">
          <table class="table is-fullwidth">
            <tbody>
              <tr>
                <td width="400">Activated</td>
                <td>
                  <div class="tabs is-toggle is-small">
                    <ul>
                      <li :class="{ 'is-active': pckg.IsActive }">
                        <a @click="pckg.IsActive = true">
                          <span>Yes</span>
                        </a>
                      </li>
                      <li :class="{ 'is-active': !pckg.IsActive }">
                        <a @click="pckg.IsActive = false">
                          <span>No</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Monthly fixed fee</td>
                <td>
                  <div class="field has-addons">
                    <p class="control">
                      <a class="button is-static">&euro;</a>
                    </p>
                    <p class="control">
                      <input
                        type="text"
                        v-model.number="pckg.MonthPrice"
                        class="input"
                      />
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <div
            v-for="meetingtype in meetingtypes"
            :key="meetingtype.MeetingtypeId"
            class="box"
          >
            <nav class="navbar" role="navigation" aria-label="main navigation">
              <div id="navbarBasicExample" class="navbar-menu">
                <div class="navbar-start">
                  <span class="has-text-weight-bold">{{
                    meetingtype.MeetingtypeId | getMeetingtypeName
                  }}</span>
                </div>

                <div class="navbar-end">
                  <div class="navbar-item">
                    <label class="checkbox">
                      <div class="field">
                        <input
                          :id="'mt_' + meetingtype.MeetingtypeId"
                          type="checkbox"
                          class="switch"
                          v-model="meetingtype.IsActive"
                          @change="meetingtypeClicked"
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </nav>

            <div v-if="meetingtype.IsActive">
              <table class="table is-fullwidth">
                <tbody>
                  <tr>
                    <td>Fee</td>
                    <td class="has-text-right">
                      <div class="field has-addons">
                        <p class="control">
                          <a class="button is-static">&euro;</a>
                        </p>
                        <p class="control">
                          <input
                            type="text"
                            v-model.number="meetingtype.Fee"
                            class="input"
                          />
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td width="400">Max fee</td>
                    <td class="has-text-right">
                      <div class="field has-addons">
                        <p class="control">
                          <a class="button is-static">&euro;</a>
                        </p>
                        <p class="control">
                          <input
                            type="text"
                            v-model.number="meetingtype.MaxTotalFee"
                            class="input"
                          />
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Allow prices</td>
                    <td class="has-text-right">
                      <div class="tabs is-toggle is-small">
                        <ul>
                          <li :class="{ 'is-active': meetingtype.AllowPrices }">
                            <a @click="meetingtype.AllowPrices = true">
                              <span>Yes</span>
                            </a>
                          </li>
                          <li
                            :class="{ 'is-active': !meetingtype.AllowPrices }"
                          >
                            <a @click="meetingtype.AllowPrices = false">
                              <span>No</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Check capacity</td>
                    <td class="has-text-right">
                      <div class="tabs is-toggle is-small">
                        <ul>
                          <li
                            :class="{ 'is-active': meetingtype.CheckCapacity }"
                          >
                            <a @click="meetingtype.CheckCapacity = true">
                              <span>Yes</span>
                            </a>
                          </li>
                          <li
                            :class="{ 'is-active': !meetingtype.CheckCapacity }"
                          >
                            <a @click="meetingtype.CheckCapacity = false">
                              <span>No</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- <div class="box">
        <p class="subtitle pt-3">Backend limitations</p>

        <table v-if="pckg" class="table is-fullwidth">
          <tbody>
            <tr>
              <td>Can create backend reservation</td>
              <td>
                <input
                  type="checkbox"
                  v-model="pckg.BackendCreateReservation"
                />
              </td>
            </tr>
            <tr>
              <td>Max. spaces</td>
              <td>
                <input type="text" class="input" v-model="pckg.MaxSpaces" />
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->

        <div class="box">
          <p class="subtitle pt-3">Functionalities</p>

          <table v-if="pckg" class="table is-fullwidth">
            <tbody v-if="pckg.Bundles && pckg.Bundles.length > 0">
              <tr v-for="(bundle, index) in pckg.Bundles" :key="index">
                <td>{{ bundle.Name }}</td>
                <td width="100" class="has-text-right">
                  <a
                    @click="deleteBundle(bundle)"
                    class="has-text-danger has-icon"
                  >
                    <span class="icon is-small">
                      <font-awesome-icon :icon="['fas', 'trash-alt']" />
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div class="select is-small">
                    <select @change="addBundle" v-model="newBundle">
                      <option :value="null">Select bundle</option>
                      <option
                        v-for="(bundle, index) in bundles"
                        :key="index"
                        :value="bundle"
                        >{{ bundle.Name }}</option
                      >
                    </select>
                  </div>
                </td>
                <td width="100" class="has-text-right">
                  <a @click="addBundle" class="is-small has-icon">
                    <span class="icon is-small">
                      <font-awesome-icon :icon="['fas', 'plus']" />
                    </span>
                    <span>Add</span>
                  </a>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    packageId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      meetingtypes: [],
      newBundle: null,
    }
  },

  computed: {
    ...mapState('bundleStore', ['bundles']),
    ...mapState('channelStore', ['channel']),
    ...mapState('packageStore', ['pckg']),
  },

  watch: {
    pckg: function(newValue) {
      if (newValue) {
        this.modelData()
      }
    },
  },

  created() {
    if (!this.pckg) {
      this.getPackage(this.packageId)
    }

    if (this.bundles.length === 0) {
      this.getAllBundles(this.channelId)
    }
  },

  mounted() {},

  beforeDestroy() {
    this.setPackage(null)
  },

  methods: {
    ...mapActions('bundleStore', ['getAllBundles']),
    ...mapActions('packageStore', ['getPackage', 'editPackage']),
    ...mapMutations('packageStore', ['setPackage']),

    modelData() {
      if (this.pckg && this.pckg.Meetingtypes) {
        for (let i = 0; i < this.pckg.Meetingtypes.length; i++) {
          let mt = this.pckg.Meetingtypes[i]

          let fee = 0
          let maxTotalFee = 0

          if (mt.MeetingtypeId === 1) {
            fee = this.pckg.SeatFeeMS
            maxTotalFee = this.pckg.MaxSeatFeeTotalMS
          }

          if (mt.MeetingtypeId === 2) {
            fee = this.pckg.SeatFeeWS
            maxTotalFee = this.pckg.MaxSeatFeeTotalWS
          }

          this.meetingtypes.push({
            MeetingtypeId: mt.MeetingtypeId,
            IsActive: true,
            AllowPrices: mt.AllowPrices,
            CheckCapacity: mt.CheckCapacity,
            Fee: fee,
            MaxTotalFee: maxTotalFee,
          })
        }
      }

      // Add meetingtypes not selected, but available in channel
      for (let j = 0; j < this.channel.Meetingtypes.length; j++) {
        let cmt = this.channel.Meetingtypes[j]

        let index = this.meetingtypes.findIndex(
          (m) => m.MeetingtypeId === cmt.MeetingtypeId
        )
        if (index === -1) {
          this.meetingtypes.push({
            MeetingtypeId: cmt.MeetingtypeId,
            IsActive: false,
            AllowPrices: true,
            CheckCapacity: true,
            Fee: 0,
            MaxTotalFee: 0,
          })
        }
      }

      this.meetingtypes = this.meetingtypes.sort(
        (a, b) => a.MeetingtypeId - b.MeetingtypeId
      )
    },

    meetingtypeClicked(meetingtype) {
      if (!meetingtype.IsActive) {
        meetingtype.Fee = 0
        meetingtype.MaxTotalFee = 0

        if (meetingtype.MeetingtypeId === 1) {
          self.pckg.SeatFeeMS = 0
          self.pckg.MaxSeatFeeTotalMS = 0
        }

        if (meetingtype.MeetingtypeId === 2) {
          self.pckg.SeatFeeWS = 0
          self.pckg.MaxSeatFeeTotalWS = 0
        }
      }
    },

    addBundle() {
      if (this.newBundle) {
        this.pckg.Bundles.push(this.newBundle)
        this.newBundle = null
      }
    },

    deleteBundle(bundle) {
      let index = this.pckg.Bundles.findIndex((b) => b.Id === bundle.Id)
      if (index > -1) {
        this.pckg.Bundles.splice(index, 1)
      }
    },

    save() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        self.pckg.Meetingtypes = []
        let activeMeetingtypes = self.meetingtypes.filter((mt) => mt.IsActive)

        for (let i = 0; i < activeMeetingtypes.length; i++) {
          let mt = activeMeetingtypes[i]

          if (mt.MeetingtypeId === 1) {
            self.pckg.SeatFeeMS = mt.Fee
            self.pckg.MaxSeatFeeTotalMS = mt.MaxTotalFee
          }

          if (mt.MeetingtypeId === 2) {
            self.pckg.SeatFeeWS = mt.Fee
            self.pckg.MaxSeatFeeTotalWS = mt.MaxTotalFee
          }

          self.pckg.Meetingtypes.push({
            MeetingtypeId: mt.MeetingtypeId,
            AllowPrices: mt.AllowPrices,
            CheckCapacity: mt.CheckCapacity,
          })
        }

        self
          .editPackage(self.pckg)
          .then((response) => {
            if (response.status === 200) {
              // Send message to display toast on screen
              EventBus.$emit('showToast', {
                type: 'info',
                message: 'Package updated!',
              })

              self.onClickCancel()
            }
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
